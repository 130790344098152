<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
            <el-form-item prop="name" label="标题">
                <el-input size="small" v-model="formData.title" placeholder="标题"></el-input>
            </el-form-item>
            <el-form-item label="封面图" prop="cover" label-width="100px">
                <file-upload-img :url.sync="formData.cover"></file-upload-img>
                <span>（590 * 340）</span>
            </el-form-item>
            <el-form-item prop="name" label="跳转地址">
                <el-input size="small" v-model="formData.go_url" placeholder="跳转地址"></el-input>
            </el-form-item>
            <el-form-item prop="intro" label="介绍">
                <el-input size="small" v-model="tmpIntro" placeholder="写完按回车" @keyup.enter.native="introChange"></el-input>
                <el-tag
                    style="margin-right: 10px;"
                    v-for="tag in formData.intro"
                    :key="tag"
                    closable
                    @close="handleClose(tag)"
                    :type="tag">
                    {{tag}}
                </el-tag>
            </el-form-item>
            <el-form-item label="排序值" prop="sort" label-width="100px">
                <el-input-number v-model="formData.sort" :min="0"  label="排序值"></el-input-number>
            </el-form-item>
            <el-form-item label=" ">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {addCategory, editCategory, detail} from './api'
import {DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'
import FileUploadImg from "@/components/uploader/uploader-img.vue";

export default {
    name: 'AddOrEdit',
    components: {FileUploadImg},

    data() {
        return {
            options: [],
            DICT_SYSTEM_SEX,
            DICT_SYSTEM_STATUS,

            tmpIntro: '',

            tags: [
                { name: '标签一', type: '' },
                { name: '标签二', type: 'success' },
                { name: '标签三', type: 'info' },
                { name: '标签四', type: 'warning' },
                { name: '标签五', type: 'danger' }
            ],

            formData: {
                title: '',
                cover: '',
                intro: [],
                go_url: '',
                sort: 0,
            },

            rules: {
                // name: [{required: true, message: '分类名称不能为空', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getAdminDetail()
    },

    methods: {
        introChange() {
            if (this.tmpIntro.length <= 0) {
                return;
            }

            this.formData.intro.push(this.tmpIntro)
            this.tmpIntro = ''
        },
        handleClose(tmpIntro) {
            this.formData.intro = this.formData.intro.filter(item => item != tmpIntro)
        },
        updateSex(val) {
            this.formData.sex = val
        },
        updateStatus(val) {
            this.formData.status = val
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editCategory(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addCategory(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getAdminDetail() {
            this.formData = await detail(this.$route.params.id)
        },
        cancel() {
            this.$router.back(-1)
        },
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
